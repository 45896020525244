import React, { useEffect } from "react";
import asyncRoute from "./helpers/hoc/asyncRoute";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { Layout, theme, notification } from "antd";
import { get, isEqual, isEmpty } from "lodash";
import { useSelector } from "react-redux";
import routes from "./routes";

// import Theme from './containers';

const Loader = asyncRoute(() => import("./components/Loader"));
const Theme = asyncRoute(() => import("./containers"));
const Signin = asyncRoute(() => import("./containers/Authentication/Signin"));
const PostSignin = asyncRoute(() =>
  import("./containers/Authentication/PreSignin")
);

const App = () => {
  const location = useLocation();
  const Navigate = useNavigate();

  const showSpinner = useSelector((state) => state.app.showFullSpinner);
  // const showSpinner = false;
  const isAuthenticated = JSON.parse(localStorage.getItem("AuthData"));
  const PreSignInData = JSON.parse(localStorage.getItem("PreSignInData"));
  const storeSelected = Number(
    JSON.parse(localStorage.getItem("storeSelected"))
  );

  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type, message) => {
    api[type]({
      message,
      // description:
    });
  };
  // THIS IS FOR MAKING DYNAMIC ROUTING WHERE THE USER WILL NOT FALL IN BETWEEN THE ROUTES AND SEE THE PAGE WHERE ROUTE NOT FOUND
  //   const paths = ['/','/onesync', '/onesync/settings/', '/onesync/settings/user', '/onesync/settings/user/'];

  // useEffect(() => {
  //     const currentPath = window.location.pathname;
  //     if (paths.includes(currentPath)) {
  //         Navigate('user/list');
  //     }
  // }, []);
  function toTitleCase(str) {
    return (
      str
        ?.split(" ") // Split the string into words
        .map(
          (
            word // Map over each word
          ) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() // Capitalize the first letter and make the rest lowercase
        )
        .join(" ") || "SignIn"
    ); // Join the words back together with a space
  }
  function handleStore(store) {
    let strName = PreSignInData?.storeList?.map((stoe) => {
      if (stoe.id === store) {
        return stoe.name;
      }
    });

    return strName ? " - " + String(strName) : "";
  }
  useEffect(() => {
    let c = location?.pathname?.split("/") || [];

    document.title = `${toTitleCase(
      c[2] == "home" ? "dashboard" : c[2]
    )}${handleStore(storeSelected)}`;
  }, [location]);

  useEffect(() => {
    if (isEmpty(isAuthenticated) && !isEmpty(PreSignInData)) {
      Navigate(`${routes.signIn}`);
    } else if (
      isEmpty(isAuthenticated) ||
      window.location.pathname === "/" ||
      window.location.pathname === `/${routes.mainRoute}` ||
      window.location.pathname === `/${routes.mainRoute}/`
    ) {
      Navigate(`${routes.preSignIn}`);
    }
  }, []);

  return (
    <>
      {showSpinner && <Loader />}
      {contextHolder}
      <Routes>
        <Route
          path={`/${routes.preSignIn}`}
          element={
            <Signin
              blue10={""}
              openNotificationWithIcon={openNotificationWithIcon}
            />
          }
        />
        <Route
          path={`/${routes.signIn}`}
          element={
            <PostSignin
              blue10={""}
              openNotificationWithIcon={openNotificationWithIcon}
            />
          }
        />
        {!isEmpty(isAuthenticated) && (
          <Route
            path={`/${routes.mainRoute}/*`}
            element={
              <Theme
                blue10={""}
                openNotificationWithIcon={openNotificationWithIcon}
              />
            }
          />
        )}
      </Routes>
    </>
  );
};

export default App;
